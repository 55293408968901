import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";

import {
  CustomerType,
  OrderedCustomerRegistrationStatus,
  RegistrationType,
} from "../domainObject/DomainDataType";
import LocationDomain from "../domainObject/Location";
import NotificationService from "../helper/NotificationService";
import RestService, { RemoteURL } from "../helper/RestService";
import { sortLocationDomainObj } from "../helper/Utility";
import authService from "../login/LoginService";
import { Routes } from "../route/Routes";
import { CompanyAttachmentRegistration } from "./CompanyAttachmentRegistration";
import { CompanyRegistration } from "./CompanyRegistration";
import { CustomerRegistrationProgressBar } from "./CustomerRegistrationProgressBar";
import { OTPGeneration } from "./OTPGeneration";
import { OTPVerification } from "./OtpVerification";
import { PersonalAttachmentRegistration } from "./PersonalAttachmentRegistration";
import { PersonalInformationRegistration } from "./PersonalInformationRegistration";
import { PhoneNumberRegistration } from "./PhoneNumberRegistration";

export const CustomerRegistration = (props) => {
  const [status, setStatus] = useState(props.status);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [customerId, setCustomerId] = useState(props.customerId);
  const [companyId, setCompanyId] = useState(props.companyId);
  const [registrationDoneModal, setRegistrationDoneModal] = useState(false);

  const DIVISION_API_URL = RemoteURL.LOCATION.GET.ALL_DIVISION;
  const [allDivision, setAllDivision] = useState([]);
  const history = useHistory();

  function isNewOnlineRegistration() {
    let bool =
      status === OrderedCustomerRegistrationStatus.NEW_REGISTRATION &&
      props.registrationType === RegistrationType.ONLINE;
    return bool;
  }

  function isNewOfflineRegistration() {
    let bool =
      status === OrderedCustomerRegistrationStatus.NEW_REGISTRATION &&
      props.registrationType === RegistrationType.OFFLINE;
    return bool;
  }

  function isRegistrationDone(registrationStatus) {
    let bool = status === registrationStatus;
    return bool;
  }

  function updateStatus(status) {
    setStatus(status);
  }

  function updatePhoneNumber(phoneNumber) {
    setPhoneNumber(phoneNumber);
  }

  function updateCustomerId(customerId) {
    setCustomerId(customerId);
  }

  function updateCompanyId(companyId) {
    setCompanyId(companyId);
  }

  function handleServerError(error) {
    NotificationService.showRestServiceError(error);
  }

  function getAllDivision() {
    function extractData(result) {
      let i = 0;
      let divisions = [];
      for (i; i < result.length; i++) {
        divisions.push(Object.assign(new LocationDomain.Division(), result[i]));
      }
      divisions.sort(sortLocationDomainObj);
      setAllDivision(divisions);
    }

    RestService.get(DIVISION_API_URL, extractData, handleServerError, false);
  }

  function redirectToCustomerPortalPage() {
    setRegistrationDoneModal(false);
    if (props.registrationType === RegistrationType.ONLINE) {
      if (authService.isLoggedIn()) {
        window.location.reload();
      } else {
        history.replace(Routes.CUSTOMER.PORTAL.HOME);
      }
    } else {
      history.replace(Routes.EMPLOYEE_PORTAL.HOME);
    }
  }

  function showRegistrationDoneModal() {
    setRegistrationDoneModal(true);
  }

  function getRegistrationDoneModal() {
    return (
      <Modal show={registrationDoneModal} onHide={redirectToCustomerPortalPage}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            {props.registrationType === RegistrationType.ONLINE
              ? "Thank you. Please log in to your portal to get the first connection."
              : "Customer registration complete."}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={redirectToCustomerPortalPage}>
            Go
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  useEffect(() => {
    if (
      isRegistrationDone(
        OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED
      ) &&
      props.customerType === CustomerType.INDIVIDUAL
    ) {
      showRegistrationDoneModal();
    }

    if (
      isRegistrationDone(
        OrderedCustomerRegistrationStatus.COMPANY_ATTACHMENT_REGISTERED
      )
    ) {
      showRegistrationDoneModal();
    }
  }, [status]);

  useEffect(() => {
    if (
      (isRegistrationDone(
        OrderedCustomerRegistrationStatus.PHONE_NUMBER_REGISTERED
      ) ||
        isRegistrationDone(
          OrderedCustomerRegistrationStatus.COMPANY_REGISTERED
        )) &&
      allDivision.length === 0
    ) {
      getAllDivision();
    }
  });

  return (
    <div>
      {getRegistrationDoneModal()}
      <CustomerRegistrationProgressBar
        customerType={props.customerType}
        registrationStatus={status}
      />
      {isNewOnlineRegistration() ? (
        <OTPGeneration
          updateStatus={updateStatus}
          updatePhoneNumber={updatePhoneNumber}
          handleServerError={handleServerError}
        />
      ) : null}
      {isRegistrationDone(OrderedCustomerRegistrationStatus.OTP_GENERATION) ? (
        <OTPVerification
          updateStatus={updateStatus}
          handleServerError={handleServerError}
          phoneNumber={phoneNumber}
        />
      ) : null}

      {isRegistrationDone(OrderedCustomerRegistrationStatus.OTP_VERIFICATION) ||
      isNewOfflineRegistration() ? (
        <PhoneNumberRegistration
          updateStatus={updateStatus}
          updateCustomerId={updateCustomerId}
          phoneNumber={phoneNumber}
          handleServerError={handleServerError}
          registrationType={props.registrationType}
          customerType={props.customerType}
        />
      ) : null}
      {isRegistrationDone(
        OrderedCustomerRegistrationStatus.PHONE_NUMBER_REGISTERED
      ) && props.customerType === CustomerType.CORPORATE ? (
        <CompanyRegistration
          customerId={customerId}
          allDivision={allDivision}
          updateStatus={updateStatus}
          updateCompanyId={updateCompanyId}
          handleServerError={handleServerError}
        />
      ) : null}
      {(isRegistrationDone(
        OrderedCustomerRegistrationStatus.PHONE_NUMBER_REGISTERED
      ) &&
        props.customerType === CustomerType.INDIVIDUAL) ||
      isRegistrationDone(
        OrderedCustomerRegistrationStatus.COMPANY_REGISTERED
      ) ? (
        <PersonalInformationRegistration
          customerId={customerId}
          customerType={props.customerType}
          allDivision={allDivision}
          updateStatus={updateStatus}
          handleServerError={handleServerError}
        />
      ) : null}

      {isRegistrationDone(
        OrderedCustomerRegistrationStatus.PERSONAL_INFO_REGISTERED
      ) ? (
        <PersonalAttachmentRegistration
          updateStatus={updateStatus}
          customerId={customerId}
          handleServerError={handleServerError}
        />
      ) : null}

      {isRegistrationDone(
        OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED
      ) && props.customerType === CustomerType.CORPORATE ? (
        <CompanyAttachmentRegistration
          updateStatus={updateStatus}
          companyId={companyId}
          handleServerError={handleServerError}
        />
      ) : null}
    </div>
  );
};
CustomerRegistration.defaultProps = {
  status: OrderedCustomerRegistrationStatus.PERSONAL_ATTACHMENT_REGISTERED,
  registrationType: RegistrationType.ONLINE,
  customerType: CustomerType.CORPORATE,
};
