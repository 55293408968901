import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import {
  AccountType,
  CustomerType,
  NumberExistenceStatus,
  RegistrationType,
} from "../domainObject/DomainDataType";
import RestService, { RemoteURL } from "../helper/RestService";

import { Routes } from "../route/Routes";
import { ConnectionRegistrationForm } from "./ConnectionRegistrationForm";
import NotificationService, {
  NotificationMessage,
  NotificationType,
} from "../helper/NotificationService";
import CustomModal, { ModalType } from "../common/modal/CustomModal";
import { CheckEmptyObject } from "../helper/Utility";

export const ConnectionRegistration = (props) => {
  const [validated, setValidated] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [registrationType, setRegistrationType] = useState(null);

  const [productInfo, setProductInfo] = useState(null);
  const [productList, setProductList] = useState([]);
  const [showProductListViewModal, setShowProductListViewModal] =
    useState(false);

  const [installationAddressId, setInstallationAddressId] = useState(null);
  const [billingAddressId, setBillingAddressId] = useState(null);
  const [numberOfConnections, setNumberOfConnections] = useState(1);
  const [
    isNumberOfConnectionFormDisabled,
    setIsNumberOfConnectionFormDisabled,
  ] = useState(false);
  const [customerAddressList, setCustomerAddressList] = useState([]);

  const [creditLimit, setCreditLimit] = useState([]);
  const [securityDeposit, setSecurityDeposit] = useState([]);
  const [concurrentChannel, setConcurrentChannel] = useState([]);
  const [number, setNumber] = useState([]);

  const [updatedField, setUpdatedField] = useState(null);
  const [updatedIndex, setUpdatedIndex] = useState(null);
  const [updatedValue, setUpdatedValue] = useState(null);

  const [isFixedValue, setIsFixedValue] = useState(true);
  const [isFixedValueDisabled, setIsFixedValueDisabled] = useState(false);
  const [isExistingConnection, setIsExistingConnection] = useState(false);
  const [fixedConcurrentChannel, setFixedConcurrentChannel] = useState(null);
  const [fixedCreditLimit, setFixedCreditLimit] = useState(null);
  const [fixedSecurityDeposit, setFixedSecurityDeposit] = useState(null);
  const [connectionForms, setConnectionForms] = useState([]);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [loadingModalShow, setLoadingModalShow] = useState(false);

  const history = useHistory();
  const prevNumOfConnections = usePrevious(numberOfConnections);
  const prevIsExistingConnection = usePrevious(isExistingConnection);
  let { custId } = useParams();

  const STATE_IDENTIFIER = {
    CREDIT_LIMIT: "CREDIT_LIMIT",
    SECURITY_DEPOSIT: "SECURITY_DEPOSIT",
    CONCURRENT_CHANNEL: "CONCURRENT_CHANNEL",
    NUMBER: "NUMBER",
  };

  function handleServerError(error) {
    setLoadingModalShow(false);
    NotificationService.showRestServiceError(error);
  }

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    if (registrationType === RegistrationType.ONLINE) {
      history.replace(Routes.CUSTOMER.PORTAL.EXISTING_CONNECTION_REQUEST);
    } else {
      history.replace(Routes.EMPLOYEE_PORTAL.HOME);
    }
  };

  function formSubmitSuccessModal() {
    return (
      <Modal show={showSuccessModal} onHide={handleCloseSuccessModal}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <p>
            Please check status of your existing connection requests. If any
            charge is due, pay them from detail option.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-info mr-auto"
            onClick={handleCloseSuccessModal}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

  function extractConnectionRegistrationResponse(response) {
    setLoadingModalShow(false);
    if (response.message == null) {
      if (response.length > 0) {
        setShowSuccessModal(true);
      } else {
        let error = {};
        error.message = "Connection registration failed!!";
        NotificationService.showRestServiceError(error);
      }
    } else {
      NotificationService.showRestServiceError(response);
    }
  }

  function createConnectionsRequestObject() {
    let connectionObjs = [];
    if (!isFixedValue) {
      for (let i = 0; i < numberOfConnections; i++) {
        let connectionObj = {};
        connectionObj.concurrentChannel = concurrentChannel[i];
        connectionObj.creditLimit = creditLimit[i];
        if (securityDeposit[i] == null) {
          connectionObj.securityDeposit = creditLimit[i];
        } else {
          connectionObj.securityDeposit = securityDeposit[i];
        }
        if (number[i] != null && number[i] !== "") {
          connectionObj.number = number[i];
        } else {
          connectionObj.number = null;
        }
        connectionObjs.push(connectionObj);
      }
    }
    return connectionObjs;
  }

  function registerConnections() {
    setLoadingModalShow(true);
    let request = {
      customerId: customerId,
      productId: productInfo.id,
      numberOfConnection: numberOfConnections,
      isFixedValue: isFixedValue,
      isExistingConnection: isExistingConnection,
      fixedConcurrentChannel: fixedConcurrentChannel,
      fixedCreditLimit: fixedCreditLimit,
      fixedSecurityDeposit: fixedSecurityDeposit,
      installationAddressId: installationAddressId,
      billingAddressId: billingAddressId,
      connections: createConnectionsRequestObject(),
    };
    RestService.post(
      RemoteURL.CONNECTION.CREATE,
      request,
      extractConnectionRegistrationResponse,
      handleServerError
    );
  }

  function extractNumberStatusResponse(response) {
    let data = response.data;
    let error = {};
    error.message = "";
    for (let i = 0; i < data.length; i++) {
      if (data[i].status !== NumberExistenceStatus.FOUND_IN_EXTERNAL) {
        if (error.message === "") {
          error.message += "Invalid numbers: " + data[i].number;
        } else {
          error.message += ", " + data[i].number;
        }
      }
    }
    if (error.message !== "") {
      handleServerError(error);
    } else {
      registerConnections();
    }
  }

  function checkNumberExistence() {
    let numberToSearch = [];
    for (let i = 0; i < number.length; i++) {
      if (number[i] != null && number[i] !== "") {
        numberToSearch.push(number[i]);
      }
    }
    if (numberToSearch.length !== 0) {
      let url =
        RemoteURL.CONNECTION.CHECK_NUMBER_EXISTENCE_STATUS +
        "?numbers=" +
        number;
      RestService.get(url, extractNumberStatusResponse, handleServerError);
    } else {
      registerConnections();
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    setValidated(true);

    const form = event.currentTarget;
    if (form.checkValidity() !== false) {
      if (registrationType === RegistrationType.OFFLINE) {
        checkNumberExistence();
      } else {
        registerConnections();
      }
    }
  }

  function selectProduct(event, product) {
    setProductInfo(product);
    setShowProductListViewModal(false);
  }

  function redirectToCustomerPortalPage() {
    setShowProductListViewModal(false);
    history.replace(Routes.CUSTOMER.PORTAL.HOME);
  }

  function crateProductViewCard(product) {
    return (
      <Col>
        <Card>
          <Card.Header>{product.name}</Card.Header>
          <Card.Body>
            {product.maximumNumberOfConnection > 1 ? (
              <Card.Text>
                Max. number of Connection:{" "}
                <strong>{product.maximumNumberOfConnection}</strong>
              </Card.Text>
            ) : null}
            {product.maximumConcurrentChannel > 1 ? (
              <Card.Text>
                Max. Concurrent channel:{" "}
                <strong>{product.maximumConcurrentChannel}</strong>
              </Card.Text>
            ) : null}
            {product.setUpCharge > 0 ? (
              <Card.Text>
                Setup charge: <strong>{product.setUpCharge + " BDT"}</strong>
              </Card.Text>
            ) : null}
            {product.lineRent > 0 ? (
              <Card.Text>
                Line rent: <strong>{product.lineRent + " BDT"}</strong>
              </Card.Text>
            ) : null}
            {product.accountType === AccountType.PRE_PAID &&
              product.prepaidInitialBalance > 0 ? (
              <Card.Text>
                Initial balance:
                <strong> {product.prepaidInitialBalance + " BDT"}</strong>
              </Card.Text>
            ) : null}
            {product.accountType === AccountType.POST_PAID &&
              product.postPaidMaximumCreditLimit > 0 ? (
              <div>
                <Card.Text>
                  Max. Credit limit:{" "}
                  <strong>{product.postPaidMaximumCreditLimit + " BDT"}</strong>
                </Card.Text>
                <Card.Text>
                  Security deposit: <strong>{"Credit limit"}</strong>
                </Card.Text>
              </div>
            ) : null}
          </Card.Body>
          <Card.Footer>
            <Button
              variant="success"
              onClick={(event) => selectProduct(event, product)}
            >
              Select
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    );
  }

  function productListViewModal() {
    let prePaidProductView = [];
    let postPaidProductView = [];
    if (productList.length > 0) {
      for (let i = 0; i < productList.length; i++) {
        if (productList[i].accountType === AccountType.PRE_PAID)
          prePaidProductView.push(crateProductViewCard(productList[i]));

        if (productList[i].accountType === AccountType.POST_PAID) {
          postPaidProductView.push(crateProductViewCard(productList[i]));
        }
      }
    }

    return (
      <Modal
        show={showProductListViewModal}
        onHide={redirectToCustomerPortalPage}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="prepaidProduct" className="mb-3">
            <Tab eventKey="prepaidProduct" title="Prepaid">
              <Row xs={1} md={1} className="g-4">
                {prePaidProductView}
              </Row>
            </Tab>
            <Tab eventKey="postpaidProduct" title="Postpaid">
              <Row xs={1} md={1} className="g-4">
                {postPaidProductView}
              </Row>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }

  function extractProductListResponse(response) {
    setProductList(response);
    setShowProductListViewModal(true);
  }

  function getProductListForCustomer(customerId) {
    let isPublishedRequestParam =props.productIsPublished === null || props.productIsPublished === undefined?
       "" : "?isPublished=" + props.productIsPublished;
    let url = RemoteURL.PRODUCT.GET.BY_CUSTOMER
      + "/" + customerId + isPublishedRequestParam;
    RestService.get(url, extractProductListResponse, handleServerError, false);
  }

  function addToArray(initialArray, setterFunction, index, value) {
    let temp = [...initialArray];
    temp[index] = value;
    setterFunction([...temp]);
  }

  function updateIndexAndValue(index, value) {
    setUpdatedIndex(index);
    setUpdatedValue(value);
  }

  function addToNumber(index, value) {
    setUpdatedField(STATE_IDENTIFIER.NUMBER);
    updateIndexAndValue(index, value);
  }

  function addToCreditLimit(index, value) {
    setUpdatedField(STATE_IDENTIFIER.CREDIT_LIMIT);
    updateIndexAndValue(index, value);
  }

  function addToSecurityDeposit(index, value) {
    setUpdatedField(STATE_IDENTIFIER.SECURITY_DEPOSIT);
    updateIndexAndValue(index, value);
  }

  function addToConcurrentChannel(index, value) {
    let temp = [...concurrentChannel];
    temp[index] = value;
    setConcurrentChannel(temp);
  }

  function getVariableConnectionsForm() {
    if (!isFixedValue) {
      let temp = [];
      setConcurrentChannel([]);
      setSecurityDeposit([]);
      setNumber([]);
      setCreditLimit([]);
      for (let i = 0; i < numberOfConnections; i++) {
        temp.push(
          <ConnectionRegistrationForm
            index={i}
            registrationType={registrationType}
            accountType={productInfo.accountType}
            customerType={productInfo.customerType}
            addToNumber={addToNumber}
            maxCreditLimit={productInfo.postPaidMaximumCreditLimit}
            addToCreditLimit={addToCreditLimit}
            addToSecurityDeposit={addToSecurityDeposit}
            maxConcurrentChannelValue={productInfo.maximumConcurrentChannel}
            addToConcurrentChannel={addToConcurrentChannel}
            inputNumber={isExistingConnection}
          />
        );
      }
      setConnectionForms(temp);
    }
  }

  function getCustomerAddressList(customerId) {
    function extractCustomerAddressList(response) {
      let addressList = response.otherAddresses;
      if (response.customerType === CustomerType.INDIVIDUAL) {
        addressList.push(response.presentAddress);
      } else {
        addressList.push(response.companyInfo.address);
      }
      setCustomerAddressList(addressList);
    }

    RestService.get(
      RemoteURL.CUSTOMER.GET.Detail + "/" + customerId,
      extractCustomerAddressList,
      handleServerError
    );
  }

  useEffect(() => {
    switch (updatedField) {
      case STATE_IDENTIFIER.CONCURRENT_CHANNEL:
        addToArray(
          concurrentChannel,
          setConcurrentChannel,
          updatedIndex,
          updatedValue
        );
        break;
      case STATE_IDENTIFIER.CREDIT_LIMIT:
        addToArray(creditLimit, setCreditLimit, updatedIndex, updatedValue);
        break;
      case STATE_IDENTIFIER.SECURITY_DEPOSIT:
        addToArray(
          securityDeposit,
          setSecurityDeposit,
          updatedIndex,
          updatedValue
        );
        break;
      case STATE_IDENTIFIER.NUMBER: {
        addToArray(number, setNumber, updatedIndex, updatedValue);
        break;
      }
      default:
        break;
    }
  }, [updatedField, updatedIndex, updatedValue]);

  useEffect(() => {
    if (!isFixedValue) {
      getVariableConnectionsForm();
    } else {
      setConnectionForms([]);
    }
    if (numberOfConnections !== prevNumOfConnections) {
      getVariableConnectionsForm();
    }
  }, [isFixedValue, numberOfConnections]);

  useEffect(() => {
    if (isExistingConnection) {
      setIsFixedValue(false);
      setIsFixedValueDisabled(true);
      setNumberOfConnections(1);
      setIsNumberOfConnectionFormDisabled(true);
    } else {
      setIsFixedValueDisabled(false);
      setIsNumberOfConnectionFormDisabled(false);
    }
    if (isExistingConnection !== prevIsExistingConnection) {
      setConnectionForms([]);
      getVariableConnectionsForm();
    }
  }, [isExistingConnection, prevIsExistingConnection]);

  useEffect(() => {
    if (
      isFixedValue &&
      productInfo != null &&
      productInfo.maximumConcurrentChannel === 1
    ) {
      setFixedConcurrentChannel(1);
    } else {
      setFixedConcurrentChannel(null);
    }
  }, [isFixedValue, productInfo]);

  useEffect(() => {
    let idCustomer = null;
    if (customerId === null) {
      if (custId != null) {
        setCustomerId(custId);
        setRegistrationType(RegistrationType.ONLINE);
        idCustomer = custId;
      } else {
        setCustomerId(props.customerId);
        setRegistrationType(props.registrationType);

        idCustomer = props.customerId;
      }
    }

    getProductListForCustomer(idCustomer);

    if (customerAddressList == null || customerAddressList.length === 0) {
      getCustomerAddressList(idCustomer);
    }
  }, []);

  function showAddressSelectionForm(heading, value, setterFunction) {
    let options = [];
    options.push(<option value={""}>Click to Select {heading}</option>);
    for (let i = 0; i < customerAddressList.length; i++) {
      options.push(
        <option value={customerAddressList[i].id}>
          {customerAddressList[i].name}
        </option>
      );
    }
    return (
      <Form.Group className="singleRowField" controlId="formBasicSelect">
        <Form.Label> {heading}</Form.Label>
        <Form.Control
          as="select"
          value={value}
          onChange={(e) => {
            setterFunction(e.target.value);
          }}
          required
          readOnly={props.formReadOnly}
          disabled={props.formReadOnly}
        >
          {options}
        </Form.Control>
        <Form.Control.Feedback type="invalid">
          Please select {heading}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function showNumberOfConnectionsForm() {
    return (
      <Form.Group className="mb-3 singleRowField" controlId="formGridAddress2">
        <Form.Label>
          Number of connections (Max {productInfo.maximumNumberOfConnection})
        </Form.Label>
        <Form.Control
          type="number"
          value={numberOfConnections}
          onChange={(e) => {
            setNumberOfConnections(e.target.value);
          }}
          required
          disabled={isNumberOfConnectionFormDisabled}
          min="1"
          max={productInfo.maximumNumberOfConnection}
        />

        <Form.Control.Feedback type="invalid">
          Please input number of connection. It must be between 1 to{" "}
          {productInfo.maximumNumberOfConnection}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }

  function getFixedConcurrentChannelForm() {
    if (isFixedValue) {
      if (productInfo.maximumConcurrentChannel === 1) {
        return (
          <div>
            <Form.Group
              className="mb-3 singleRowField"
              controlId="formGridAddress2"
            >
              <Form.Label>
                Concurrent Channel (Max {productInfo.maximumConcurrentChannel})
              </Form.Label>
              <Form.Control
                type="number"
                value={1}
                required
                readOnly
                min="1"
                max={productInfo.maximumConcurrentChannel}
              />

              <Form.Control.Feedback type="invalid">
                Please input Concurrent channel. It must be between 1 to{" "}
                {productInfo.maximumConcurrentChannel}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        );
      } else {
        return (
          <Form.Group
            className="mb-3 singleRowField"
            controlId="formGridAddress2"
          >
            <Form.Label>
              Concurrent Channel (Max {productInfo.maximumConcurrentChannel})
            </Form.Label>
            <Form.Control
              type="number"
              value={fixedConcurrentChannel}
              onChange={(e) => {
                setFixedConcurrentChannel(e.target.value);
              }}
              required
              min="1"
              max={productInfo.maximumConcurrentChannel}
            />

            <Form.Control.Feedback type="invalid">
              Please input Concurrent channel. It must be between 1 to{" "}
              {productInfo.maximumConcurrentChannel}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }
    }
  }

  function getFixedCreditLimitAndSecurityDepositForm() {
    if (
      productInfo != null &&
      productInfo.accountType === AccountType.POST_PAID
    ) {
      if (isFixedValue) {
        let securityDepositEnteredManually = false;
        return (
          <Form.Group
            className="mb-3 singleRowField"
            controlId="formGridAddress2"
          >
            <Form.Label>
              Credit Limit (Max {productInfo.postPaidMaximumCreditLimit})
            </Form.Label>
            <Form.Control
              type="number"
              min="0"
              max={productInfo.postPaidMaximumCreditLimit}
              value={fixedCreditLimit}
              onChange={(e) => {
                setFixedCreditLimit(e.target.value);
                if (!securityDepositEnteredManually) {
                  setFixedSecurityDeposit(e.target.value);
                }
              }}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input credit limit. Credit limit must be between 0 to
              {productInfo.postPaidMaximumCreditLimit}
            </Form.Control.Feedback>
            <Form.Label>
              Security Deposit(Max {productInfo.postPaidMaximumCreditLimit})
            </Form.Label>
            <Form.Control
              type="number"
              min="0"
              max={productInfo.postPaidMaximumCreditLimit}
              value={fixedSecurityDeposit}
              onChange={(e) => {
                securityDepositEnteredManually = true;
                setFixedSecurityDeposit(e.target.value);
              }}
              readOnly={
                registrationType === RegistrationType.OFFLINE ? false : true
              }
              required
            />
            <Form.Control.Feedback type="invalid">
              Please input security deposit. Security deposit must be between 0
              to
              {productInfo.postPaidMaximumCreditLimit}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }
    }
  }

  function showIsFixedValueForms() {
    return (
      <div>
        <Form.Check
          className="singleRowField"
          type="switch"
          id="custom-switch"
          label="Fixed properties"
          checked={isFixedValue}
          disabled={isFixedValueDisabled}
          onChange={() => {
            setIsFixedValue(!isFixedValue);
          }}
        />
      </div>
    );
  }

  function showIsExistingConnectionForms() {
    if (registrationType === RegistrationType.OFFLINE) {
      return (
        <div>
          <Form.Check
            className="singleRowField"
            type="switch"
            id="existing-connection-switch"
            label="Existing connection"
            checked={isExistingConnection}
            onChange={() => {
              setIsExistingConnection(!isExistingConnection);
            }}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  return (
    <div>
      <h5 className="singleRowField">
        <strong>Request connection</strong>
      </h5>
      <br></br>
      <div>
        {productInfo != null ? (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            {showAddressSelectionForm(
              "Installation Address",
              installationAddressId,
              setInstallationAddressId
            )}
            {productInfo != null &&
              productInfo.accountType === AccountType.POST_PAID
              ? showAddressSelectionForm(
                "Billing Address",
                billingAddressId,
                setBillingAddressId
              )
              : null}
            {showNumberOfConnectionsForm()}
            {showIsExistingConnectionForms()}
            {showIsFixedValueForms()}
            <br></br>
            {getFixedConcurrentChannelForm()}
            {getFixedCreditLimitAndSecurityDepositForm()}
            {connectionForms}
            <Button className="singleRowField" block size="lg" type="submit">
              Request
            </Button>
          </Form>
        ) : null}
        <br></br>
      </div>
      {productListViewModal()}
      {formSubmitSuccessModal()}
      <CustomModal
        openControl={loadingModalShow}
        modalType={ModalType.LOADING_MODAL}
      />
    </div>
  );
};
